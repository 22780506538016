import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-santa-rosa-california.png'
import image0 from "../../images/cities/scale-model-of-all-first-class-car,-shuttle,-bus-services-comfortwinetours.com-in-santa-rosa-california.png"
import image1 from "../../images/cities/scale-model-of-california-welcome-center-in-santa-rosa-california.png"
import image2 from "../../images/cities/scale-model-of-luther-burbank-home-&-gardens-in-santa-rosa-california.png"
import image3 from "../../images/cities/scale-model-of-railroad-square-historic-district-in-santa-rosa-california.png"
import image4 from "../../images/cities/scale-model-of-charles-m.-schulz-museum-and-research-center-in-santa-rosa-california.png"
import image5 from "../../images/cities/scale-model-of-annex-galleries-in-santa-rosa-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Santa Rosa'
            state='California'
            image={image}
            lat='38.4468'
            lon='-122.7061'
            attractions={ [{"name": "ALL First Class Car, Shuttle, Bus Services-ComfortWineTours.com", "vicinity": "1003 Kingwood St, Santa Rosa", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 38.4446873, "lng": -122.73078190000001}, {"name": "California Welcome Center", "vicinity": "9 4th St, Santa Rosa", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 38.4375427, "lng": -122.72142839999998}, {"name": "Luther Burbank Home & Gardens", "vicinity": "204 Santa Rosa Ave, Santa Rosa", "types": ["park", "museum", "point_of_interest", "establishment"], "lat": 38.4361572, "lng": -122.71177660000001}, {"name": "Railroad Square Historic District", "vicinity": "9 4th St, Santa Rosa", "types": ["point_of_interest", "establishment"], "lat": 38.4375817, "lng": -122.7214462}, {"name": "Charles M. Schulz Museum and Research Center", "vicinity": "2301 Hardies Ln, Santa Rosa", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.4604206, "lng": -122.73587629999997}, {"name": "Annex Galleries", "vicinity": "604 College Ave, Santa Rosa", "types": ["art_gallery", "point_of_interest", "establishment"], "lat": 38.4453928, "lng": -122.71581100000003}] }
            attractionImages={ {"ALL First Class Car, Shuttle, Bus Services-ComfortWineTours.com":image0,"California Welcome Center":image1,"Luther Burbank Home & Gardens":image2,"Railroad Square Historic District":image3,"Charles M. Schulz Museum and Research Center":image4,"Annex Galleries":image5,} }
       />);
  }
}